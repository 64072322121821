<template>
  <div class="page-content">
    <div style="position: absolute; left: 30px; top: 30px; z-index: 99">
      <div class="btn-group">
        <button class="btn btn-white active">所有企业</button>
        <button class="btn btn-danger">高危企业</button>
        <button class="btn btn-warning">危险企业</button>
        <button class="btn btn-green">正常企业</button>
      </div>
      <!-- 
      <a-radio-group default-value="1" button-style="solid" >
        <a-radio-button value="1"> 所有企业 </a-radio-button>
        <a-radio-button value="2"> 正常企业 </a-radio-button>
        <a-radio-button value="5"> 异常企业 </a-radio-button>
      </a-radio-group> 
      -->
    </div>
    <div
      id="container"
      :center="{ point }"
      :style="{ height: windowHeight }"
      class="map"
      tabindex="0"
    ></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      windowHeight: "",
      point: [121.651287, 29.771813],
    };
  },
  created() {
    this.windowHeight = document.documentElement.clientHeight - 64 + "px";
    window.openDeviceDetail = this.openDeviceDetail;
    window.openProjectDetail = this.openProjectDetail;
  },
  mounted() {
    new this.$Amap.Map("container", {
      // container为容器的id
      zoom: 13, //初始化地图层级
      center: [121.651287, 29.771813], //初始化地图中心点
    });
    //   new this.$Amap.Map("container");
  },
};
</script>

<style >
#container {
  left: 0px;
  top: 0px;
}
/* 去除高德地图logo */
.amap-logo {
  display: none;
  opacity: 0 !important;
}
.amap-copyright {
  opacity: 0;
}
</style>